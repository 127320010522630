<template>
  <section id="page-content">
    <div class="nstra-container">
      <h2 class="page-title">{{ title }}</h2>
      <p class="page-subtitle">{{ subtitle }}</p>
      <slot></slot>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: String,
    subtitle: String,
  },
};
</script>
